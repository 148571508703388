import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './style/index.sass';

import Header from './components/parts/Header';
import Footer from "./components/parts/Footer";

import ContactContainer from './containers/Contact';
import HomeContainer from './containers/Home';
import ImplementationContainer from './containers/Implementation';
import VoiceOverContainer from './containers/VoiceOver';
import AboutMeContainer from './containers/AboutMe';
import SoundDesignContainer from './containers/SoundDesign';
import MusicContainer from './containers/Music';
import LinearContainer from './containers/Linear';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path='/'>
          <HomeContainer />
        </Route>

        <Route path="/about-me">
          <AboutMeContainer />
        </Route>

        <Route path="/music">
          <MusicContainer />
        </Route>

        <Route path="/linear">
          <LinearContainer />
        </Route>

        <Route path="/sounddesign">
          <SoundDesignContainer />
        </Route>

        <Route path="/voice-over">
          <VoiceOverContainer />
        </Route>

        <Route path="/implementation">
          <ImplementationContainer />
        </Route>

        <Route path="/contact">
          <ContactContainer />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
