import React, { Fragment, useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { NavLink, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function Menu() {

    const location = useLocation();
    const [visiability, changeVisiability] = useState('hidden');
    const [mobileSize, changemobileSize] = useState('');
    const [mobileMenuShow, changeMobileMenuShow] = useState(false);



    //optimize mobile menu
    let displayWidth = window.innerWidth;

    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '/admin' || location.pathname === '/adminhome') {
            // console.log(location.pathname)
            changeVisiability('hidden');
        } else {
            // console.log('On other page');
            // console.log(location.pathname)

            changeVisiability('show');
        }
        window.dispatchEvent(new Event('resize'));
    })

    window.addEventListener("resize", () => {
        // console.log(window.innerWidth);
        displayWidth = window.innerWidth;
        if (displayWidth < 993) {
            if (displayWidth < 993) {
                changemobileSize('mobile-resolution');
            } else {
                changemobileSize('');
                changeMobileMenuShow(false);
            }
            // console.log(mobileSize);
        }
    });


    return (
        <Fragment >
            <Container className={visiability} mobmenu={mobileMenuShow ? "show" : null}>
                <Row className={mobileSize}>
                    <Col lg="3" md="5" className='col-hd-3'>

                        <Link className='logo-link' to="/about-me" >
                            <span className="logo logo_left">DIMOLO</span><span className="logo logo_right">SOUND</span>
                        </Link>


                    </Col>
                    <div className="mobile-hamburger"
                        onClick={() => {
                            changeMobileMenuShow(!mobileMenuShow);
                        }}
                    >
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                    <Col md="7" lg="7" className='offset-lg-2 col-xxl-8 offset-xxl-1  col-smhd-8 offset-smhd-1 col-hd-7 offset-hd-2'>

                        <nav className="menu">
                            <ul>
                                <li>
                                    <NavLink to="about-me" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >about me</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                                <li>
                                    <NavLink to="/sounddesign" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >sound design</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                                <li>
                                    <NavLink to="/music" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >music</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                                <li>
                                    <NavLink to="/linear" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >linear media</NavLink>
                                    <span className="menu-active"> </span>
                                </li>

                                <li>
                                    <NavLink to="/voice-over" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >voice over</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                                <li>
                                    <NavLink to="/implementation" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >implementation</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                                <li>
                                    <NavLink to="/contact" activeClassName={"active"}
                                        onClick={() => {
                                            changeMobileMenuShow(!mobileMenuShow);
                                        }}

                                    >contact</NavLink>
                                    <span className="menu-active"> </span>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}


export default Menu;