import gql from "graphql-tag";

const LINEAR_PAGE_QUERY = gql`
    query linearMedia{
        linearMedia{
            MediaContent{
                title_row_one
                title_row_two
                url
                soundcloud_iframe
                contentType
            }
        }
    }
`;

export default LINEAR_PAGE_QUERY;
