import Music from "../../components/Music";
import Query from "../../components/Query";
import MUSIC_PAGE_QUERY from "../../queries/music_page";

const MusicContainer = () => {
  return (
    <Query query={MUSIC_PAGE_QUERY}>
      {({ data: { pageMusic } }) => {
        console.log('MusicContainer pageMusic:', pageMusic);
        // console.log(pageMusic.MediaContent);

        // console.log(pageMusics.MediaContent);
        // pageMusics = pageMusics.sort((a, b) => {
        //     return  Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
        // });
        return <Music page={pageMusic} pagelength={pageMusic.MediaContent.length} />;
        // return <h1>w2</h1>;

      }}
    </Query>
  );
};

export default MusicContainer;
