import Linear from "../../components/Music";
import Query from "../../components/Query";
import LINEAR_PAGE_QUERY from "../../queries/linear_page";

const LinearContainer = () => {
  return (
    <Query query={LINEAR_PAGE_QUERY}>
      {({ data: { linearMedia } }) => {
        console.log('LinearContainer linearMedia:', linearMedia);
        // console.log(pageMusic.MediaContent);

        // console.log(pageMusics.MediaContent);
        // pageMusics = pageMusics.sort((a, b) => {
        //     return  Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
        // });
        return <Linear page={linearMedia} pagelength={linearMedia?.MediaContent?.length || 0} />;
        // return <h1>w2</h1>;

      }}
    </Query>
  );
};

export default LinearContainer;
